import { createContext } from 'react';

import type { Segment } from '@onecamera/core';
import type { SetStateAction } from 'react';
import type { SubmittedResponse } from '~/components/Camera/src/features/CreateResponse/responsesAPI';
import type { RecorderStatusEnum } from '~/enums';

type RecorderProgress = {
  progress: number;
  recorderSegments: Segment[];
  stalled: boolean;
  submittedResponse: SubmittedResponse | undefined;
  thumbnailBlobUrl: string | undefined;
  setProgress: (progress: SetStateAction<number>) => void;
  setRecorderSegments: (segments: SetStateAction<Segment[]>) => void;
  setStalled: (stalled: SetStateAction<boolean>) => void;
  setSubmittedResponse: (response: SetStateAction<SubmittedResponse | undefined>) => void;
  setThumbnailBlobUrl: (thumbnailBlobUrl: SetStateAction<string | undefined>) => void;
};

type RecorderContextTypes = {
  /** Indicates if the recorder is currently active. */
  isRecorderActive: boolean;

  /** Indicates if the recorder was activated while another recording is active. */
  isRecorderReactivated: boolean;

  /** Indicates that the user recording has chosen to upload a video asynchronously. */
  isUploadingAsync: boolean;

  /** Activates the recorder. */
  loadRecorder: (recorderPageProps: TSFix) => void;

  /** Sets the `isRecorderActive` state. */
  setIsRecorderActive: (isActive: SetStateAction<boolean>) => void;

  /** Sets the `isRecorderReactivated` state. */
  setIsRecorderReactivated: (isReactivated: SetStateAction<boolean>) => void;

  /** The page props for the recorder. */
  recorderProps: TSFix | undefined;

  /** Hides the recorder from the page, but does not re-render it. */
  hideRecorder: (shouldRedirect?: boolean) => void;

  /** Data relating to the recorder's progress. */
  recorderProgress: RecorderProgress;

  /** Status of the recorder video upload. */
  recorderStatus: RecorderStatusEnum;

  /** Sets the `isUploadingAsync` state. */
  setIsUploadingAsync: (isAsyncUpload: SetStateAction<boolean>) => void;

  /** Sets the `recorderStatus` state. */
  setRecorderStatus: (status: SetStateAction<RecorderStatusEnum>) => void;

  /** Resets the recorder context values to their initial default values. */
  reset: () => void;
  
  /** Tracker of upload retry attempts */
  uploadRetryCount: number;

  /** Increments the number of upload attempts */
  retryUpload(): void;
};

const recorderContextInitialValues = {
  uploadRetryCount: 0,
  retryUpload : () => {},
  isRecorderActive: false,
  isRecorderReactivated: false,
  isUploadingAsync: false,
  recorderProps: undefined,
  recorderStatus: 'idle',
  hideRecorder: () => {},
  loadRecorder: (_recorderPageProps: TSFix) => {},
  setIsRecorderActive: () => {},
  setIsRecorderReactivated: () => {},
  setIsUploadingAsync: () => {},
  setRecorderStatus: () => {},
  recorderProgress: {
    progress: 0,
    recorderSegments: [],
    stalled: false,
    submittedResponse: undefined,
    thumbnailBlobUrl: '',
    setProgress: () => {},
    setRecorderSegments: () => {},
    setStalled: () => {},
    setSubmittedResponse: () => {},
    setThumbnailBlobUrl: () => {},
  },
  reset: () => {},
};

export default createContext(recorderContextInitialValues as RecorderContextTypes);
